import {HttpClient, HttpEvent, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractApiService} from '@app/core-module/services/abstract.api.service';
import {AccType} from '@shared/models/acc-type';
import {Account} from '@shared/models/account';
import {Balance} from '@shared/models/balance';
import {CreditScheduleConfigurationEntry} from '@shared/models/credit-schedule-configuration-entry';
import {FinancialInstitution} from '@shared/models/financial-institution';
import {IdType} from '@shared/models/id-type';
import {RdcFilter} from '@shared/models/non-db/rdc-filter';
import {TransactionFilter} from '@shared/models/non-db/transaction-filter';
import {TransferBetweenAccounts} from '@shared/models/non-db/transfer-between-accounts';
import {OrgType} from '@shared/models/org-type';
import {Payor} from '@shared/models/payor';
import {Person} from '@shared/models/person';
import {Profile} from '@shared/models/profile';
import {RDC} from '@shared/models/rdc';
import {RdcsAmountPerDay} from '@shared/models/rdcs-amount-per-day';
import {RdcsPerDay} from '@shared/models/rdcs-per-day';
import {ReconcileReport} from '@shared/models/reconcile-report';
import {ScannerInfo} from '@shared/models/scanner.info';
import {Transaction} from '@shared/models/transaction';
import {User} from '@shared/models/user';
import {UsersType} from '@shared/models/users-type';
import {Observable, of} from 'rxjs';
import {SelfRegisteredUser} from '@shared/models/self-registered-user';
import {AnsweredQuestion, QuestionDefinition} from '@shared/models/question-definition';
import {ScanningResult} from '@shared/models/scanning.result';
import {ScanningMessage} from '@shared/models/scanning.message';
import {TranType} from '@shared/models/tran-type';
import {TranStatus} from '@shared/models/tran-status';
import {WireTransferForm} from '@shared/models/wire-transfer-form';
import {CustomerDocument} from '@shared/models/customer-document';
import currencies from '@shared/assets/data/currencies/currencies';
import {WireTransferBank} from '@shared/models/wire-transfer-bank';
import {RdcActivity} from '@shared/models/rdc-activity';
import {WireTransferFormActivity} from '@shared/models/wire-transfer-form-activity';
import {WireFormFilter} from '@shared/models/non-db/wire-form-filter';
import {StatusCode} from '@shared/models/status-code';
import {WireTransferFormStatusTypeConverted} from '@shared/models/wire-transfer-form-status-type';
import {KycProfile} from '@shared/models/kyc-profile';
import {FiKycProfilesFilter} from '@shared/models/non-db/fi-kyc-profiles-filter';
import {FiBankProfilesFilter} from '@shared/models/non-db/fi-bank-profiles-filter';
import {IncludeKycProfile} from '@shared/utils/utils';
import {QuestionFormData} from '@app/shared-module/utils/question.utils';
import {Country_ISO} from '@shared/models/country_iso';
import {UpstreamBank} from '@shared/models/upstream-bank';
import {FIAPIConfig} from '@shared/utils/api';

@Injectable()
export class ApiService extends AbstractApiService {


  constructor(protected http: HttpClient) {
    super(http);
  }


  public getCurrentProfile(): Observable<Profile> {
    return this.createGetRequest<Profile>('/auth/current-user');
  }

  public getUser(id: string): Observable<User> {
    return this.createGetRequest<User>('/bank/users/' + id);
  }

  public getUserWithAPIKey(id: string): Observable<User> {
    return this.createGetRequest<User>(`/bank/users/${id}?includeAPIKey=true`);
  }

  public getScannerInfo(user: User) {
    return this.createGetRequest<ScannerInfo>('/scanner/info/' + user.id);
  }

  public eject(): Observable<void> {
    return this.createPostRequest<void>('/scanner/eject');
  }

  public getCurrentBank(): Observable<FinancialInstitution> {
    return this.createGetRequest<FinancialInstitution>('/bank');
  }

  public updateCurrentBank(bank: FinancialInstitution) {
    return this.createPutRequest<FinancialInstitution>('/bank', bank);
  }

  public getCurrentBankWithAPI(): Observable<FinancialInstitution> {
    return this.createGetRequest<FinancialInstitution>('/bank/api');
  }

  public updateCurrentBankAPIConfig(apiConfig: FIAPIConfig) {
    return this.createPutRequest<FinancialInstitution>('/bank/api', apiConfig);
  }

  public getAccounts(): Observable<Account[]> {
    return this.createGetRequest<Account[]>('/accounts');
  }

  public getAllTransactions(): Observable<Transaction[]> {
    return this.createGetRequest<Transaction[]>('/transactions');
  }

  public getWireTransferTransactions(wireTransferId: number): Observable<Transaction[]> {
    return this.createGetRequest<Transaction[]>('/transactions/wire-transfer/' + wireTransferId);
  }

  public getAccount(id: number): Observable<Account> {
    return this.createGetRequest<Account>('/accounts/' + id);
  }

  public getDefaultCheckingAccount(): Observable<Account> {
    return this.createGetRequest<Account>('/accounts/checking/default');
  }

  public getScanningQueueRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/bank/rdcs/status/scanning-queue-rdcs');
  }

  public getNotProcessedRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/bank/rdcs/status/not-processed');
  }

  public getNotProcessedECheckRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/bank/rdcs/status/e-check');
  }

  public getRDC(id: number): Observable<RDC> {
    return this.createGetRequest<RDC>('/bank/rdcs/' + id);
  }

  public getRDCs(filter: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/bank/rdcs/status/all', filter);
  }

  public getRDCMoreInfoRequiredActivity(id: number): Observable<RdcActivity> {
    return this.createGetRequest<RdcActivity>(`/bank/rdcs/${id}/activity/more-info-required`);
  }

  public downloadRDCMoreInfoRequiredDocument(activityId, documentId: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/bank/rdcs/activity/${activityId}/documents/${documentId}/download`);
  }

  public deleteRDC(rdc: RDC): Observable<RDC> {
    return this.createDeleteRequest<RDC>('/bank/rdcs/' + rdc.id);
  }

  public getRDCsCompletedInfo(): Observable<{ num_created: number, amount: number }> {
    return this.createGetRequest<{ num_created: number, amount: number }>('/bank/rdcs/info/completed');
  }

  public getRDCsPerDay(): Observable<RdcsPerDay[]> {
    return this.createGetRequest<RdcsPerDay[]>('/bank/rdcs/chart/created-per-day');
  }

  public getRDCsAmountPerDay(): Observable<RdcsAmountPerDay[]> {
    return this.createGetRequest<RdcsAmountPerDay[]>('/bank/rdcs/chart/created-amount-per-day');
  }

  public getPersons(filter: FiKycProfilesFilter): Observable<Person[]> {
    return this.createGetRequestWithParams<Person[]>('/persons', filter);
  }

  public getPersonsAllowedAsPayee(query: string, amount: number): Observable<Person[]> {
    return this.createGetRequest<Person[]>('/persons/allowed-as-payee/by-query?q=' + query + '&amount=' + amount);
  }

  public getSuggesterPayor(rdc: RDC): Observable<Payor> {
    return this.createGetRequest<Payor>('/payors/suggested/' + rdc.id);
  }

  public getPayorsByName(identifier: string): Observable<Payor[]> {
    return this.createGetRequest<Payor[]>('/payors/by-name?q=' + identifier);
  }

  public addPerson(person: Person, options: { toDuplicate: boolean }): Observable<Person> {
    return this.createPostRequest<Person>('/persons', {person, options});
  }

  public addPersonsFromExcel(file: any): Observable<Person[]> {
    const formData = new FormData();
    formData.append('excel-file', file);
    return this.createPostRequest<Person[]>('/persons/from-excel-file', formData);
  }

  public downloadPersonsExcelTemplate(): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/persons/download-template');
  }

  public editPerson(person: Person): Observable<Person> {
    return this.createPutRequest<Person>('/persons', person);
  }

  public getTransactions(account: Account): Observable<Transaction[]> {
    return this.createGetRequest<Transaction[]>('/transactions/account/' + account.id);
  }

  public getTransactionsPdf(account: Account): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/transactions/pdf/account/' + account.id);
  }

  public getMonthlyStatementPdf(accountId: number, year: number, month: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/statements/monthly?accountId=' + accountId + '&year=' + year + '&month=' + month);
  }

  public getYearlyStatementPdf(accountId: number, year: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/statements/yearly?accountId=' + accountId + '&year=' + year);
  }

  public createTransferBetweenAccounts(transferBetweenAccounts: TransferBetweenAccounts): Observable<TransferBetweenAccounts> {
    return this.createPostRequest<TransferBetweenAccounts>('/transactions/transfer-between-accounts/', transferBetweenAccounts);
  }

  public getBalances(includeKycProfile: IncludeKycProfile): Observable<Balance[]> {
    return this.createGetRequest<Balance[]>('/accounts/activity/balance?includeKycProfile=' + includeKycProfile);
  }

  public getBalancesPdf(): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/accounts/activity/balance-pdf');
  }

  public updateUser(user: User): Observable<User> {
    return this.createPutRequest<User>('/bank/users/' + user.id, user);
  }

  public enableAdminUser(user: User): Observable<User> {
    return this.createPutRequest<User>('/bank/users/' + user.id + "/enable", {});
  }

  public updateApprovalLimitForUser(user: User, approvalLimit): Observable<User> {
    return this.createPutRequest<User>('/bank/users/' + user.id + '/approval-limit', {approvalLimit: approvalLimit});
  }

  public deleteUser(user: User): Observable<User> {
    return this.createDeleteRequest<User>('/bank/users/' + user.id);
  }

  public getCurrentBankUsers(): Observable<User[]> {
    return this.createGetRequest<User[]>('/bank/users');
  }

  public addUserToCurrentBank(user: User): Observable<User> {
    return this.createPostRequest<User>('/bank/users', user);
  }

  // NOT ALLOWED
  // public addAccount(account: Account): Observable<Account> {
  //   return this.createPostRequest<Account>('/accounts', account);
  // }

  public addRDC(rdc: RDC): Observable<RDC> {
    return this.createPostRequest<RDC>('/bank/rdcs', rdc);
  }

  public processRDC(rdc: RDC): Observable<RDC> {
    return this.createPutRequest<RDC>('/bank/rdcs-process/' + rdc.id, rdc);
  }

  public updateRDC(rdc: RDC): Observable<RDC> {
    return this.createPutRequest<RDC>('/bank/rdcs/' + rdc.id, rdc);
  }

  public getAccTypes(): Observable<AccType[]> {
    return this.createGetRequest<AccType[]>('/general/acc-types');
  }

  public getIdTypes(): Observable<IdType[]> {
    return this.createGetRequest<IdType[]>('/general/id-types');
  }

  public getUserTypes(): Observable<UsersType[]> {
    return this.createGetRequest<UsersType[]>('/general/user-types');
  }

  public getOrgTypes(): Observable<OrgType[]> {
    return this.createGetRequest<OrgType[]>('/general/org-types');
  }

  public getReconcileReports(): Observable<ReconcileReport[]> {
    return this.createGetRequest('/reconcile-reports/all');
  }

  public getReconcileReportFilePdf(id: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/reconcile-reports/download?id=' + id);
  }

  public getCreditScheduleConfig(): Observable<CreditScheduleConfigurationEntry[]> {
    return this.createGetRequest('/credit-schedule-configuration');
  }

  public resendEmail(profile: Profile) {
    return this.createGetRequest('/auth/resend/' + profile.id);
  }

  public updateUserInfo(user: User) {
    return this.createPutRequest('/profile/user', user);
  }

  public updateSelfRegisteredUserInfo(selfRegisteredUser: SelfRegisteredUser) {
    return this.createPutRequest('/profile/self_registered_user', selfRegisteredUser);
  }

  public changeProfilePassword(form: {}) {
    return this.createPostRequest('/profile/change-password', form);
  }

  public getTransactionsFiltered(filter: TransactionFilter): Observable<Transaction[]> {
    return this.createGetRequestWithParams<Transaction[]>('/transactions', filter);
  }

  public getProfileAnswerFile(filename: string): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/profile/questions/download/' + filename);
  }

  public uploadRdcLocallyScannedImage(fileName: string, formData: FormData) {
    return this.createPostRequest<void>('/browser-scanning/save-image?fileName=' + fileName, formData);
  }

  public checkDuplicateLocallyScannedRdc(scanningResult: ScanningResult): Observable<ScanningMessage> {
    return this.createPostRequest('/browser-scanning/check-duplicate', scanningResult);
  }

  public checkDuplicateLocallyScannedRdcBatch(scanningResult: ScanningResult): Observable<ScanningMessage> {
    return this.createPostRequest('/browser-scanning/check-duplicate/batch', scanningResult);
  }

  public tfaSetup(): Observable<{ dataURL: string, tempSecret: string }> {
    return this.createPostRequest<{ dataURL: string, tempSecret: string }>('/auth/tfa-setup', {});
  }

  public tfaSetupVerify(tfaCode: { tfaCode: string }) {
    return this.createPostRequest('/auth/tfa-verify', tfaCode);
  }

  public tfaDisable() {
    return this.createPostRequest('/auth/tfa-delete', {});
  }

  public downloadSearchActivityPdf(filters: any): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequestWithParams('/transactions/search-activity-pdf', filters);
  }

  public getTransactionStatuses(): Observable<TranStatus[]> {
    return this.createGetRequest<TranStatus[]>('/activity-filters/transaction-statuses');
  }

  public getTransactionTypes(): Observable<TranType[]> {
    return this.createGetRequest<TranType[]>('/activity-filters/transaction-types');
  }

  public getRdcStatusCodes(): Observable<StatusCode[]> {
    return this.createGetRequest<StatusCode[]>('/activity-filters/rdc-status-codes');
  }

  public fillMissingDataWithExcel(file: any): Observable<{ updatedRDCs: RDC[], populatedRDCs: RDC[] }> {
    const formData = new FormData();
    formData.append('excel', file);
    return this.createPostRequest<{
      updatedRDCs: RDC[],
      populatedRDCs: RDC[]
    }>('/bank/populate-data-from-excel', formData);
  }

  public importNotProcessedECheckRDCs(file: any): Observable<RDC[]> {
    const formData = new FormData();
    formData.append('excel', file);
    return this.createPostRequest<RDC[]>('/bank/e-check/import', formData);
  }

  public downloadExcelTemplate(): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/bank/download-template');
  }

  public downloadECheckImportTemplate(): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/bank/e-check/download-template');
  }

  public createWireTransferForm(formData: FormData, isSegregateAccountWF: boolean): Observable<WireTransferForm> {
    return this.createPostRequest('/wire-transfer-form?isSegregateAccountWF=' + isSegregateAccountWF, formData);
  }

  public createOwnWireTransferForm(formData: FormData): Observable<WireTransferForm> {
    return this.createPostRequest('/wire-transfer-form/own', formData);
  }

  public createWireTransferFormsFromExcel(file: any): Observable<WireTransferForm[]> {
    const formData = new FormData();
    formData.append('excel-file', file);
    return this.createPostRequest<WireTransferForm[]>('/wire-transfer-form/from-excel-file', formData);
  }

  public exportWireTransferFormsToExcel(wireTransferFormIds): Observable<HttpResponse<Blob>> {
    return this.createBlobPostRequest('/wire-transfer-form/export-excel', {wireTransferFormIds});
  }

  public downloadWireTransferFormsExcelTemplate(): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/wire-transfer-form/download-template');
  }

  public getOutgoingWireTransferForms(filter: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/wire-transfer-form/outgoing', filter);
  }

  public getWireTransferFormStatuses(): Observable<{
    outgoingStatuses: WireTransferFormStatusTypeConverted[],
    incomingStatuses: WireTransferFormStatusTypeConverted[]
  }> {
    return this.createGetRequest<{
      outgoingStatuses: WireTransferFormStatusTypeConverted[],
      incomingStatuses: WireTransferFormStatusTypeConverted[]
    }>('/wire-transfer-form/status-types');
  }

  public getIncomingWireTransferForms(filter: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/wire-transfer-form/incoming', filter);
  }

  public getWireTransferForm(id: number): Observable<WireTransferForm> {
    return this.createGetRequest(`/wire-transfer-form/${id}`);
  }

  public getWireTransferFormDocuments(id: number): Observable<CustomerDocument[]> {
    return this.createGetRequest(`/wire-transfer-form/${id}/documents`);
  }

  public getWireTransferStateActivity(id: number): Observable<WireTransferFormActivity> {
    return this.createGetRequest<WireTransferFormActivity>(`/wire-transfer-form/${id}/activity/current-state`);
  }

  public exportWireTransferFormToPdf(id: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/wire-transfer-form/${id}/pdf-export`);
  }

  public getWireTransferMoreInfoRequiredActivity(id: number): Observable<WireTransferFormActivity> {
    return this.createGetRequest<WireTransferFormActivity>(`/wire-transfer-form/${id}/activity/more-info-required`);
  }

  public downloadWireTransferMoreInfoRequiredDocument(activityId: number, documentId: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/wire-transfer-form/activity/${activityId}/documents/${documentId}/download`);
  }

  public postWireTransferDocumentsAndComment(wireTransferId: number, formData: FormData): Observable<void> {
    return this.createPostRequest<void>(`/wire-transfer-form/status/more-info-required/${wireTransferId}/upload`, formData);
  }

  public getPersonsAllowedForWire(identifier: string, options: {
    onlyFiClients: boolean,
    isSegregateAccountTransfer: boolean,
    upstreamBankId: number,
    includeAccount: boolean
  }): Observable<Person[]> {
    return this.createPostRequest<Person[]>('/persons/allowed-for-wire', {
      q: identifier,
      onlyFiClients: options.onlyFiClients,
      isSegregateAccountTransfer: options.isSegregateAccountTransfer,
      upstreamBankId: options.upstreamBankId,
      includeAccount: options.includeAccount
    });
  }

  public getKycProfile(id: number): Observable<KycProfile> {
    return this.createGetRequest<KycProfile>(`/kyc-profile/profile/${id}`);
  }

  public getOrCreatePersonAsOwnTransferOriginator(): Observable<Person> {
    return this.createPutRequest<Person>(`/persons/own-transfer-originator`, {});
  }

  public getOrCreatePersonAsOwnTransferOriginatorByAccountId(accountId: number): Observable<Person> {
    return this.createPutRequest<Person>(`/persons/own-transfer-originator/by-account/${accountId}`, {});
  }

  public getWireTransferBanks(filter: FiBankProfilesFilter): Observable<WireTransferBank[]> {
    return this.createGetRequestWithParams<WireTransferBank[]>('/wire-transfer-form/banks', filter);
  }

  public getWireTransferBankById(wireTransferBankId: number): Observable<WireTransferBank> {
    return this.createGetRequest<WireTransferBank>('/wire-transfer-form/banks/' + wireTransferBankId);
  }

  public deleteWireTransferBankById(wireTransferBankId: number): Observable<void> {
    return this.createDeleteRequest('/wire-transfer-form/banks/by-id/' + wireTransferBankId);
  }

  public createWireTransferBank(wireTransferBank: WireTransferBank): Observable<WireTransferBank> {
    return this.createPostRequest<WireTransferBank>('/wire-transfer-form/banks', wireTransferBank);
  }

  public updateWireTransferBank(wireTransferBank: WireTransferBank): Observable<WireTransferBank> {
    return this.createPutRequest<WireTransferBank>('/wire-transfer-form/banks', wireTransferBank);
  }

  public getWireTransferBankByQuery(identifier: string): Observable<WireTransferBank[]> {
    return this.createPostRequest<WireTransferBank[]>('/wire-transfer-form/banks/by-query', {q: identifier});
  }

  public getQuestionsForWireTransferBank(wireTransferBankId: number): Observable<QuestionDefinition[]> {
    return this.createGetRequest(`/wire-transfer-form/banks/by-id/${wireTransferBankId}/questions`);
  }

  public getAnsweredQuestionsForWireTransferBank(wireTransferBankId: number): Observable<AnsweredQuestion[]> {
    return this.createGetRequest(`/wire-transfer-form/banks/by-id/${wireTransferBankId}/questions/answered`);
  }

  public answerQuestionForWireTransferBankWithProgress(
    wireTransferBankId: number, questionId: string, formData: FormData): Observable<HttpEvent<void>> {
    return this.createPostRequestWithProgress<void>(
      `/wire-transfer-form/banks/by-id/${wireTransferBankId}/questions/${questionId}`, formData);
  }

  public getProfileFromWireTransferBankId(id: number): Observable<Profile> {
    return this.createGetRequest<Profile>(`/wire-transfer-form/banks/${id}/profile`);
  }

  public getCurrencies(): Observable<{ value: string, currencyName: string }[]> {
    return of(currencies);
  }

  public saveCheckNumber(rdcId: number, checkNumber: string): Observable<RDC> {
    return this.createPutRequest<RDC>(`/bank/rdcs/${rdcId}/check-fields`, {checkNumber: checkNumber});
  }

  public saveCheckRoutingAba(rdcId: number, checkRoutingAba: string): Observable<RDC> {
    return this.createPutRequest<RDC>(`/bank/rdcs/${rdcId}/check-fields`, {checkRoutingAba: checkRoutingAba});
  }

  public saveCheckAccountNo(rdcId: number, checkAccNo: string): Observable<RDC> {
    return this.createPutRequest<RDC>(`/bank/rdcs/${rdcId}/check-fields`, {checkAccNo: checkAccNo});
  }

  public submitWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/wire-transfer-form/submit/${wireTransferForm.id}`, {});
  }

  public setUltimateBeneficiaryPerson(form: {
    ultimate_beneficiary_person: Person
  }, wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/wire-transfer-form/set-ultimate-beneficiary/${wireTransferForm.id}`, form);
  }

  public discardWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/wire-transfer-form/discard/${wireTransferForm.id}`, {});
  }

  public getDocuments(profileId: number): Observable<CustomerDocument[]> {
    return this.createGetRequest(`/kyc-profile/by-id/${profileId}/documents`);
  }

  public postCustomerDocuments(profileId: number, formData: FormData): Observable<CustomerDocument[]> {
    return this.createPostRequest(`/kyc-profile/by-id/${profileId}/documents`, formData);
  }

  public deleteCustomerDocument(profileId: number, documentId: number): Observable<CustomerDocument> {
    return this.createDeleteRequest(`/kyc-profile/by-id/${profileId}/documents/${documentId}`);
  }

  public getDocument(profileId: number, documentId: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/kyc-profile/by-id/${profileId}/documents/${documentId}`);
  }

  public getDocumentOfWireTransferForm(wireTransferFormId: number, documentId: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/wire-transfer-form/by-id/${wireTransferFormId}/documents/${documentId}`);
  }

  public getQuestionsForProfile(profileId: number): Observable<QuestionDefinition[]> {
    return this.createGetRequest(`/kyc-profile/by-id/${profileId}/questions`);
  }

  public getAnsweredQuestionsForProfile(profileId: number): Observable<AnsweredQuestion[]> {
    return this.createGetRequest(`/kyc-profile/by-id/${profileId}/questions/answered`);
  }

  public answerQuestionForProfileWithProgress(
    profileId: number, questionId: string, questionFormData: QuestionFormData): Observable<HttpEvent<void>> {

    const formData = new FormData();
    formData.append('blocksJson', JSON.stringify(questionFormData.blocks));
    questionFormData.files.forEach(it => {
      formData.append('files', it);
    });
    return this.createPostRequestWithProgress<void>(`/kyc-profile/by-id/${profileId}/questions/${questionId}`, formData);
  }

  public postRdcDocumentsAndComment(rdcId: number, formData: FormData): Observable<void> {
    return this.createPostRequest<void>(`/bank/rdcs/status/more-info-required/${rdcId}/upload`, formData);
  }

  public clonePerson(person: Person): Observable<Person> {
    return this.createPostRequest<Person>('/persons/clone/' + person.id);
  }

  public updateAnsweredQuestionsForProfile(profileId: number, questionId: string, questionFormData: QuestionFormData): Observable<void> {

    const formData = new FormData();
    formData.append('blocksJson', JSON.stringify(questionFormData.blocks));
    questionFormData.files.forEach(it => {
      formData.append('files', it);
    });
    return this.createPutRequest(`/kyc-profile/update-answer/${profileId}/${questionId}`, formData);
  }

  public getAccountByKycProfileId(id: number): Observable<Account> {
    return this.createGetRequest<Account>('/accounts/kyc-profile-id/' + id);
  }

  public getCountries(): Observable<Country_ISO[]> {
    return this.createGetRequest<Country_ISO[]>('/countries-iso/get-all');
  }

  public getUpstreamBanksCutoffTimes(): Observable<UpstreamBank[]> {
    return this.createGetRequest<UpstreamBank[]>('/upstream-banks/cutoff-times');
  }

  public getCanCreateSegregatedAccounts(): Observable<boolean> {
    return this.createGetRequest<boolean>('/bank/can-create-segregated-accounts');
  }

  public getCanCreate3rdPartyTransfer(): Observable<boolean> {
    return this.createGetRequest<boolean>('/bank/can-create-third-party-transfer');
  }

  getReturnedOutgoingWireTransferForms(filter: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/wire-transfer-form/returned-outgoing-wires', filter);
  }

  getReturnedIncomingWireTransferForms(filter: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/wire-transfer-form/returned-incoming-wires', filter);
  }
  public editOrClonePerson(person: Person): Observable<Person> {
    return this.createPutRequest<Person>('/persons/edit-or-clone', person);
  }
}
